import { array, bool, func, node, object, string } from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import localeConfigs from 'utils/localeConfigs';

import { retrieveBagHandler } from 'utils/bag';

export const i18nContext = createContext(undefined);

const I18nProvider = ({
  t,
  locale,
  countries,
  children,
  profileData,
  detectedCountry,
  appmode,
  popupInfo,
  additionalAttributes,
  userInteracted,
  i18nPage,
  storeData,
  setStoreData,
  unbxdInitialSearch,
}) => {
  const [bagLength, setBagLength] = useState(0);
  const [bag, setBag] = useState(null);
  const [bagHasMembersOnlyProduct, setBagHasMemberOnlyProduct] = useState(false);
  const [promoLabel, setPromoLabel] = useState(null);
  const [promoLabelType, setPromoLabelType] = useState('progress');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryShadow, setSearchQueryShadow] = useState('');
  const [disabledProducts, setDisabledProducts] = useState(null);
  // utilizo un queryShadow porque el searchQuery acciona un useEffect en la pagina de /search, asi que tengo que poder distinguirlo para el input en mobile que se puede accionar con el boton de la lupa.
  const [recommendedProductsForBag, setRecommendedProductsForBag] = useLocalStorage('bag-recommended', []);
  const [unbxdSearchResults, setUnbxdSearchResults] = useState(unbxdInitialSearch);

  const wrappedLocaleConfigs = {};

  Object.keys(localeConfigs).forEach((localeConfKey) => {
    wrappedLocaleConfigs[localeConfKey] = localeConfigs[localeConfKey](t);
  });

  useEffect(() => {
    // si la bolsa esta vacia quitar productos recomendados que se hayan quedado
    if (Array.isArray(bag) && bag.length === 0 && recommendedProductsForBag.length > 0) {
      setRecommendedProductsForBag([]);
    }
  }, [bag]);

  useEffect(() => {
    retrieveBagHandler({
      locale,
      config: profileData.config,
      setBag,
      setBagHasMemberOnlyProduct,
      setBagLength,
      setPromoLabel,
      setPromoLabelType,
      setDisabledProducts,
    });
  }, []);

  return (
    <i18nContext.Provider
      value={{
        t,
        locale,
        countries,
        localeConfigs: wrappedLocaleConfigs,
        profileData,
        bagLength,
        setBagLength,
        bag,
        setBag,
        bagHasMembersOnlyProduct,
        setBagHasMemberOnlyProduct,
        promoLabel,
        setPromoLabel,
        promoLabelType,
        setPromoLabelType,
        searchQuery,
        setSearchQuery,
        searchQueryShadow,
        setSearchQueryShadow,
        detectedCountry,
        appmode,
        popupInfo,
        additionalAttributes,
        recommendedProductsForBag,
        setRecommendedProductsForBag,
        userInteracted,
        i18nPage,
        storeData,
        setStoreData,
        disabledProducts,
        unbxdInitialSearch,
        unbxdSearchResults,
        setUnbxdSearchResults,
      }}
    >
      {children}
    </i18nContext.Provider>
  );
};

export function useI18n() {
  const context = useContext(i18nContext);
  if (context === undefined) {
    throw new Error('useI18n must be used within a I18nProvider');
  }
  return context;
}

I18nProvider.propTypes = {
  children: node,
  locale: string,
  countries: array,
  t: func,
  profileData: object,
  detectedCountry: string,
  appmode: bool,
  popupInfo: object,
  additionalAttributes: object,
  userInteracted: bool,
  i18nPage: string,
  storeData: object,
  setStoreData: func,
};

export default I18nProvider;
