import { get, getPrivateUrl, post } from 'utils/apiWrapper';
import { CLOUDFRONT_MISS } from 'utils/constants/i18n';
import { IS_CAMPER } from 'utils/constants/system';
import { logError } from 'utils/error';
import { prepareHeadersFromContext, handleOriginByCookie, getHash } from 'utils/helpers';
import {
  I18N_MULTIPLE_ROUTE,
  CHECK_PROFILE_ROUTE,
  I18N_CREATE_ROUTE,
  COUNTRIES_ROUTE,
  FAQS,
  PAYMENT_METHODS_GET_ROUTE,
  SHIPPING_METHODS_GET_ROUTE,
  NEWSLETTER_SUBSCRIBE,
  RIBBON,
  NEWSLETTER_INFO_ROUTE,
  SEO,
  NEWSLETTER_UPDATE,
  NEWSLETTER_UNSUBSCRIBE,
  LOG_ROUTE,
  REGIONS_AND_CITIES,
  NEWSLETTER_PREFERENCES,
  GET_NEWSLETTER_PREFERENCES,
  SELLIGENT_POPUP,
  GET_POPUP_INFO,
  DONATION_BEGIN,
  DONATION_DONATE,
  I18N_LABELS,
  GLOBAL_RETURN_METHODS,
  I18N_NEW,
  TWS_EVENT_SUBSCRIBE,
  TWS_EVENT_UNSUBSCRIBE,
  TWS_EVENT_CONDITIONS,
  I18N_GET_SINGLE,
  CHILE_COMUNAS,
  ENRICHMENT_TWS,
} from 'utils/routes';
import { getUtmParams } from 'utils/utm';

export const getTranslations = ({ context, profile, relParents }) =>
  new Promise((resolve, reject) => {
    // hacer la query a pageName y si loadGeneric? también las comunes
    // una query compuesta que nos permita pedir ambas o una
    // sería lo mejor
    const translationsURL = `${I18N_MULTIPLE_ROUTE}?profile=${profile}&parentId=${relParents.join(',')}`;
    const headers = prepareHeadersFromContext(context);

    get({ path: translationsURL, headers })
      .then(({ data }) => {
        const { status, labels } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          resolve(labels);
        }
      })
      .catch(reject);
    // Resolver en base a loadGeneric
  });

export const getSingleTranslation = (profile, rel_parent, rel_anchor) =>
  new Promise((resolve, reject) => {
    const translationsURL = `${I18N_GET_SINGLE}?profile=${profile}&parentId=${rel_parent}&anchorId=${rel_anchor}`;

    get({ path: translationsURL })
      .then(({ data }) => {
        const { status, label } = data;

        if (status !== 'OK') {
          reject(data);
        } else {
          resolve(label.value);
        }
      })
      .catch(reject);
  });

export const checkProfile = ({ context, profile, filters, ...params }) =>
  new Promise((resolve, reject) => {
    let url = `${CHECK_PROFILE_ROUTE}?profile=${profile}`;

    if (params) {
      Object.keys(params).forEach((paramKey) => {
        url = `${url}&${paramKey}=${params[paramKey]}`;
      });
    }

    if (filters) {
      url = `${url}&${filters}`;
    }

    const headers = prepareHeadersFromContext(context);
    get({ path: url, headers })
      .then((response) => {
        if (response && response.data) {
          const { data } = response;

          if (data) {
            if (data.audit) delete data.audit;
            if (data.status) delete data.status;
          }
          resolve(data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const createTranslations = (lang, labels) =>
  new Promise((resolve, reject) => {
    post({
      path: I18N_CREATE_ROUTE,
      data: {
        labels,
        profile: lang,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        }
      })
      .catch(reject);
  });

export const getCountries = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({ path: `${COUNTRIES_ROUTE}?profile=${profile}`, headers })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            resolve(data.countries);
          }
        }
      })
      .catch(reject);
  });

export const getPaymentMethods = ({ context, lang, countryId }) =>
  new Promise((resolve, reject) => {
    const path = `${PAYMENT_METHODS_GET_ROUTE}?profile=${lang}&countryId=${countryId}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then((response) => {
        if ('data' in response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getShippingMethods = ({ context, lang, countryId }) =>
  new Promise((resolve, reject) => {
    const path = `${SHIPPING_METHODS_GET_ROUTE}?profile=${lang}&countryId=${countryId}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then((response) => {
        if ('data' in response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const subscribeTwsEvent = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: TWS_EVENT_SUBSCRIBE,
      data: {
        ...data,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const unsubscribeTwsEvent = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: TWS_EVENT_UNSUBSCRIBE,
      data: {
        ...data,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

/**
 * @typedef {Object} TranslationsObject
 * @property {string} share
 * @property {string} title
 * @property {string} titleNotMember
 * @property {string} subtitle
 * @property {string} subtitleNotMember
 * @property {string} extraInfo
 * @property {string} subscribe
 * @property {string} unsubscribe
 * @property {string} unsubscribeConfirm
 * @property {string} unsubscribeConfirmText
 */

/**
 * @param {Object} data
 * @param {string} data.profile
 * @param {string} data.countryId
 *
 * @returns {Object} response
 * @returns {string} response.urlTc
 * @returns {TranslationsObject} response.translations
 */
export const conditionsTwsEvent = (data) =>
  new Promise((resolve, reject) => {
    const searchParams = new URLSearchParams(Object.entries(data));
    get({
      path: `${TWS_EVENT_CONDITIONS}?${searchParams.toString()}`,
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const subscribeNewsletter = (data) =>
  new Promise((resolve, reject) => {
    const originFields = handleOriginByCookie();
    const utmParams = getUtmParams({ campaign: data.campaign });

    post({
      path: NEWSLETTER_SUBSCRIBE,
      data: {
        ...data,
        ...originFields,
        ...utmParams,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const updateNewsletter = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: NEWSLETTER_UPDATE,
      data,
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const unsubscribeNewsletter = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: NEWSLETTER_UNSUBSCRIBE,
      data: {
        ...data,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getRibbon = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({ path: `${RIBBON}?profile=${profile}`, headers })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            resolve(data);
          }
        }
      })
      .catch(reject);
  });

export const getFAQs = ({ context, lang }) =>
  new Promise((resolve, reject) => {
    const path = `${FAQS}?profile=${lang}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then((response) => {
        if ('data' in response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getInfo = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({ path: `${NEWSLETTER_INFO_ROUTE}?profile=${profile}`, headers })
      .then((response) => {
        if (response && 'data' in response) {
          const { data } = response;

          if (data) {
            resolve(data);
          } else {
            reject(data);
          }
        }
      })
      .catch(reject);
  });

export const getSeo = (context, queryparams = []) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({ path: `${SEO}?${queryparams.join('&')}`, headers })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            const { metaKeywords, metaDescription, revision, titlePage: pageTitle } = data;
            resolve({
              metaKeywords: metaKeywords || null,
              metaDescription,
              revision,
              pageTitle,
            });
          } else {
            reject(data);
          }
        }
      })
      .catch(reject);
  });

/**
 * Este método, como solo se usa en Cliente, lo vamos a limitar a entornos local e integra.
 * No usar nunca en Cliente en Producción. Si se necesitara en Producción ha de ser de forma teporal,
 * quitando / poniendo un "return;" al principio del método
 */
export const apiLog = ({ profile, objectNode, message }) =>
  new Promise((resolve, reject) => {
    const obj = objectNode !== undefined && objectNode !== null ? objectNode : {};
    if (message) {
      obj.message = message;
    }

    post({
      path: LOG_ROUTE,
      data: {
        profile,
        objectNode: obj,
      },
    })
      .then((response) => {
        if ('data' in response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

/**
 * Este método, como solo se usa en Cliente, lo vamos a limitar a entornos local e integra.
 * No usar nunca en Cliente en Producción. Si se necesitara en Producción ha de ser ServerSide,
 * por lo que habría que modificar el if añadiendo por ej un parámetro adicional a la llamada.
 */
export const apiLogPrivate = ({ environment, profile, objectNode, message }) =>
  new Promise((resolve, reject) => {
    if (environment === 'prod') {
      return;
    }

    const obj = objectNode !== undefined && objectNode !== null ? objectNode : {};
    if (message) {
      obj.message = message;
    }

    const base = getPrivateUrl(environment);

    post({
      base,
      path: LOG_ROUTE,
      data: {
        profile,
        objectNode: obj,
      },
    })
      .then((response) => {
        if ('data' in response) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getRegionsAndCities = (locale) =>
  new Promise((resolve, reject) => {
    get({ path: `${REGIONS_AND_CITIES}?profile=${locale}` })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            resolve(data);
          }
        }
      })
      .catch(reject);
  });

export const changeNewsletterPreferences = (body) =>
  new Promise((resolve, reject) => {
    post({
      path: NEWSLETTER_PREFERENCES,
      data: body,
    })
      .then((response) => {
        if (response) {
          const { data } = response;

          if (data && data.status === 'OK') {
            resolve(data);
          }
        }
      })
      .catch(reject);
  });

export const getNewsletterPreferences = (body) =>
  new Promise((resolve, reject) => {
    post({
      path: GET_NEWSLETTER_PREFERENCES,
      data: body,
    })
      .then((response) => {
        if (response) {
          const { data } = response;

          if (data && data.status === 'OK') {
            resolve(data);
          }
        }
      })
      .catch(reject);
  });

export const getIsSelligent = (locale) =>
  new Promise((resolve, reject) => {
    get({ path: `${SELLIGENT_POPUP}?profile=${locale}` })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK' && data.url) {
            resolve(data.url);
          } else {
            resolve(false);
          }
        }
      })
      .catch(reject);
  });

export const getPopUpInfo = ({ profile }) =>
  new Promise((resolve, reject) => {
    get({ path: `${GET_POPUP_INFO}?profile=${profile}` })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK' && data.title !== undefined) {
            resolve(data);
          } else {
            resolve(false);
          }
        }
      })
      .catch(reject);
  });

export const getDonationActive = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: DONATION_BEGIN,
      data: {
        ...data,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const makeDonation = (data) =>
  new Promise((resolve, reject) => {
    post({
      path: DONATION_DONATE,
      data: {
        ...data,
      },
    })
      .then((response) => {
        if (response && response.data && response.data.status === 'OK') {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const getI18nTimeStamp = (i18nString) =>
  new Promise((resolve, reject) => {
    const base = process.env.MISC_ENDPOINT || (IS_CAMPER ? 'https://misc.camper.com' : 'https://misc.nnormal.com');
    console.log('[i18n] Missing labels:', i18nString);

    getHash(i18nString.join()).then((hash) => {
      const path = `${I18N_LABELS}${hash}`;

      get({
        base,
        path,
      })
        .then((response) => {
          let shouldReject = false;
          let returnValue = null;

          if (response) {
            const { headers } = response;

            if (headers && headers['x-cache']) {
              const cache = headers['x-cache'];

              returnValue = cache === CLOUDFRONT_MISS;
            } else {
              shouldReject = true;
            }
          } else {
            shouldReject = true;
          }

          if (shouldReject) {
            reject(response);
          } else {
            resolve({
              hash,
              shouldPost: returnValue,
            });
          }
        })
        .catch(reject);
    });
  });
/**
 *
 * @param {String} locale
 * @param {String} countryId
 * @returns {Array.<{id: String, name: String, priceFormatted: String, infoList?: Array<string>}>}
 */
export async function getGlobalReturnMethods(locale, countryId) {
  if (!locale) {
    throw new Error('missing locale');
  }
  const r = await get({ base: process.env.API_GATEWAY, path: `${GLOBAL_RETURN_METHODS}?profile=${locale}&countryId=${countryId}` });
  return r?.data?.returnMethods ?? [];
}

export async function getTranslationsNew({ context, profile, page }) {
  const headers = prepareHeadersFromContext(context);
  const path = `${I18N_NEW}?profile=${profile}&page=${page}`;

  const result = await get({ path, headers });

  if (result && result?.data && result?.data?.labels) {
    return result.data.labels;
  }

  return result;
}

export async function getComunas({ context, profile }) {
  const path = `${CHILE_COMUNAS}?profile=${profile}`;
  try {
    const headers = prepareHeadersFromContext(context);

    const result = await get({ path, headers });

    if (result.data.status === 'OK' && Array.isArray(result?.data?.comunas)) {
      return result.data.comunas;
    }
    throw result;
  } catch (error) {
    logError({ path, error });
    return [];
  }
}

export async function enrichTwsEvent({ context, profile, payload }) {
  const path = `${ENRICHMENT_TWS}`;
  try {
    const headers = prepareHeadersFromContext(context);

    const result = await post({
      path,
      headers,
      data: {
        profile,
        ...payload,
      },
    });

    if (result.data.status === 'OK') {
      return true;
    }

    throw result;
  } catch (error) {
    logError({ path, error });
    return false;
  }
}
